/** @jsx jsx */
import { Link, graphql } from "gatsby"
import { jsx } from "@emotion/core"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Tile from "../components/tile"
import TileList from "../components/tile-list"

const HomePage = ({
  data: {
    allContentfulBlogPost: { edges: blogPosts },
  },
}) => {
  return (
    <Layout>
      <SEO title="Home" />
      {/* <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div> */}
      <TileList>
        {blogPosts.map(({ node: { id, previewImage, slug, tileColour } }) => {
          const imageUrl = previewImage && previewImage.file.url

          return (
            <Tile key={id}>
              <Link
                css={{ color: "black", textDecoration: "none" }}
                to={`/${slug}`}
              >
                <div
                  css={{
                    background:
                      (imageUrl && `url(${imageUrl})`) ||
                      tileColour ||
                      "#a67fae",
                    backgroundSize: "cover",
                    paddingTop: "100%",
                  }}
                />
                {/* <div css={{ textAlign: "center" }}>
                    {new Date(updatedAt).toLocaleDateString()}
                  </div> */}
              </Link>
            </Tile>
          )
        })}
      </TileList>
    </Layout>
  )
}

export const homePageQuery = graphql`
  query homePageQuery {
    allContentfulBlogPost(sort: { fields: postDateTime, order: DESC }, filter: {categories: {eq: "Phoenix Rising Stories"}}) {
      edges {
        node {
          id
          previewImage {
            file {
              url
            }
          }
          slug
          tileColour
          title
          updatedAt
        }
      }
    }
  }
`

export default HomePage
